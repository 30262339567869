import React, {useState} from "react";
import {Input} from "antd";
import CustomFieldWrapper from "../CustomFieldWrapper";
import {isValidValue} from "../../helper_functions";


export const CustomTextField = (props) => {
    const [InputTag] = useState(props.type==="password"?Input.Password:Input)
    const handleFieldChange = async (e) => {
        if (!props.view_only && !props.disabled && props.onChange) {
            await props.onChange(e.target.name, e.target.value);
        }
    };

    const onBlur = async (e) => {
        if (!props.view_only && !props.disabled && props.onBlur) {
            await props.onBlur(props.name, e.target.value);
        }
    };

    return (
        <CustomFieldWrapper {...props} addonAfter={null}>
            <InputTag
                {...props}
                disabled={props.disabled || props.view_only}
                onChange={handleFieldChange}
                onBlur={onBlur}
                name={props.name || ""}
                value={isValidValue(props.value)?props.value:""}
                placeholder={props.placeholder}
                status={props.error ? "error" : props.status}
                type={props.type || "string"}
                className={`mt-0 ${!props.disabled && props.view_only?"bg-white text-black":""} ${props.inputClassName}`}
                // className={`mt-0 ${props.error?"border-danger": ""}`}
            />
        </CustomFieldWrapper>
    );
};
